<template>
  <div div class="addPerson__wrap" v-show="visible">
    <el-button style="margin:20px;" plain icon="el-icon-d-arrow-left" @click="toggle(false)">返回</el-button>
    <div class="main">
      <div style="margin-top: 30px;text-align: left;font-weight: bold;" >工单信息: {{toStr({1:'待支付', 2:'待派单', 3:'待服务', 4:'已完成', 5:'已取消'},selStatus)}}</div>
      <div style="margin-top: 10px;text-align: left;font-weight: bold;color:#999;" >订单编号: {{info.order_no}}</div>
      <el-descriptions title="" :column="2" border style="margin-top:10px;">
        <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' label="服务名称">{{ info.item_str }}</el-descriptions-item>
        <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' label="服务规格">{{ info.spec_str }}</el-descriptions-item>
        <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' label="需求类别">{{ info.kinds_str }}</el-descriptions-item>
        <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' label="订单金额">{{ info.amount }}元</el-descriptions-item>
        <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' label="服务地址">{{ info.address }}</el-descriptions-item>
        <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' label="服务时间">{{ info.show_service_time }}</el-descriptions-item>
        <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' label="联系人">{{ info.name }}  {{ info.phone}}</el-descriptions-item>
        <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' label="下单时间">{{ info.create_time }}</el-descriptions-item>
        <!-- 有支付时间的再显示一下信息 -->
        <template v-if="!!info.pay_time">
          <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' label="支付时间">{{ info.pay_time }}</el-descriptions-item>
          <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' label="服务商" v-if="!!info.organization_name">{{ info.organization_name }}</el-descriptions-item>
          <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' label="指派人员" v-if="!!info.worker.nickname">{{ info.worker.nickname }}  {{ info.worker.account}}</el-descriptions-item>
          <!-- apply_time:申请补差价时间 -->
          <template v-if="!!info.apply_time"> 
            <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' label="补差金额">¥{{ info.replenish_amount }}</el-descriptions-item>
            <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' label="补差时间">{{ info.replenish_time }}</el-descriptions-item>
          </template>
          <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' label="机构收益">¥ {{ info.organization_amount }} ({{info.organization_rate}}%)</el-descriptions-item>
          <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' label="师傅收益">¥ {{ info.worker_real_amount }}</el-descriptions-item>
        </template>
        <!-- "status": //状态：1:待确认 2:待开工 3:待完工 4:已完工 5:已完成 6:已结束 -->
        <template v-if="info.status > 4">
          <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' :label="info.status == 5 ? '退款金额' : '取消金额'" v-if="info.cancel_amount > 0">¥ {{ info.cancel_amount }}</el-descriptions-item>
          <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' :label="info.status == 5 ? '退款原因' : '取消原因'" v-if="!!info.cancel_reason">{{ info.cancel_reason }}</el-descriptions-item>
        </template>
      </el-descriptions>
      
      <div style="margin-top: 30px;text-align: left;font-weight: bold;" v-if="!!info.pay_time">服务进度</div>
      <el-steps :active="stepsActive" align-center process-status="wait" style="margin:20px 0;width:100%;" v-if="!!info.pay_time">
        <el-step title="">
          <template #title>
            <span style="font-size:18px;">支付成功</span>
          </template>
          <template #description>
            <div style="color:#999;">{{info.pay_time}}</div>
          </template>
        </el-step>
        <el-step title="">
          <template #title>
            <span style="font-size:18px;">派单服务商</span>
          </template>
          <template #description>
            <div style="color:#999;">{{info.assign_time}}</div>
          </template>
        </el-step>
        <el-step title="">
          <template #title>
            <span style="font-size:18px;">指派师傅</span>
          </template>
          <template #description>
            <div style="color:#999;">{{info.to_time}}</div>
          </template>
        </el-step>
        <!-- 支付后又取消的订单展示 -->
        <el-step title="" v-if="!!info.pay_time && !!info.cancel_time">
          <template #title>
            <span style="font-size:18px;">客户取消</span>
          </template>
          <template #description>
            <div style="color:#999;">{{info.cancel_time}}</div>
          </template>
        </el-step>
        <!-- 不是取消的订单展示 -->
        <template v-if="!info.cancel_time">
          <el-step title="">
            <template #title>
              <span style="font-size:18px;">上门打卡</span>
            </template>
            <template #description>
              <div style="color:#999;">{{info.clock_time}}</div>
            </template>
          </el-step>
          <el-step title="">
            <template #title>
              <span style="font-size:18px;">核销完成</span>
            </template>
            <template #description>
              <div style="color:#999;">{{info.complete_time}}</div>
            </template>
          </el-step>
        </template>
      </el-steps>

    </div>
  </div>
</template>

<script>
export default {
  name: 'ServeOrderDetail',
  data() {
    return {
      visible: false,
      selStatus: 1,  // 列表传过来的查询状态
      info: {
        worker: {},
      },
      labelStyle: { width: '100px' },
      contentStyle: { width: '300px' },
      stepsActive: 0,
    }
  },
  methods: {
    // 获取详情
    getDetail(row) {
      this.selStatus = row.selStatus;
      this.isChange = true
      this.$http.get('/admin/flex_order/serviceDetail', {params:{id: row.id} }).then(res => {
        if(res.code === 1) {
          this.info = res.data;
          if(!!res.data.complete_time) { // 核销完成时间
            this.stepsActive = 5;
          } else if(!!res.data.clock_time || !!res.data.cancel_time) { // 上门打卡时间 或客户取消时间
            this.stepsActive = 4;
          } else if(!!res.data.check_time) { // 上门打卡时间
            this.stepsActive = 3;
          }  else if(!!res.data.assign_time) { // 派单服务商时间
            this.stepsActive = 2;
          } else if(!!res.data.pay_time) { // 核销完成时间
            this.stepsActive = 1;
          } 
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    reset(done) {
      this.isChange = false
      done && done()
    },
    toggle(show) {
      this.visible = show
      !show && this.reset()
    },
    toStr(data, key) {
      return data[key]
    }
  }
}
</script>
<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
  background-color: #fff;
  margin: 10px;
  z-index: 5 !important;
  overflow: scroll;
  .main {
    width: 80%;
    margin: 0 auto;
    text-align: center;
  }
}
.avatar_img ::v-deep img {
  width: 100%;
  height: 100%;
}

</style>


<template>
  <div class="main-view">
    <el-radio-group v-model="table.params.status" @change="handleTabs" style="margin-bottom:10px;">
      <el-radio-button :label="1">待支付({{table.paying_total}})</el-radio-button>
      <el-radio-button :label="2">待派单({{table.sending_total}})</el-radio-button>
      <el-radio-button :label="3">待服务({{table.servicing_total}})</el-radio-button>
      <el-radio-button :label="4">已完成({{table.finish_total}})</el-radio-button>
      <el-radio-button :label="5">已取消({{table.cancel_total}})</el-radio-button>
    </el-radio-group>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" size="mini">
        <el-form-item label="服务名称:" prop="item_str">
          <el-input clearable v-model.trim="table.params.item_str" placeholder="请输入标题" @clear="onSearch" @keydown.enter.native="onSearch"></el-input>
        </el-form-item>
        <el-form-item label="需求类别:" prop="kinds_arr">
          <el-cascader
            style="width:100%;"
            ref="cascaderKinds"
            v-model="table.params.kinds_arr"
            :options="kindListOpt"
            @focus="getKindList"
            @change="onSearch"
            :props="{value:'id',label:'name', checkStrictly: true }"
            ></el-cascader>
        </el-form-item>
        <el-form-item label="下单地区" prop="city">
          <el-cascader
            :popper-append-to-body="false"
            popper-class="areaCascaderWrap"
            v-model="table.params.city"
            :options="areaArr"
            clearable
            filterable
            :props="{ emitPath:false }"
            @change="onSearch">
          </el-cascader>
        </el-form-item>
         <el-form-item label="订单编号:" prop="order_no">
          <el-input clearable v-model.trim="table.params.order_no" placeholder="请输入订单编号" @clear="onSearch" @keydown.enter.native="onSearch"></el-input>
        </el-form-item>
        <el-form-item label="下单人:" prop="account" v-if="table.params.status == 2">
          <el-input clearable v-model.trim="table.params.account" placeholder="请输入" @keydown.enter.native="onSearch"></el-input>
        </el-form-item>
        <el-form-item label="服务商名称:" prop="organization_id" id="organization_id" v-if="table.params.status > 2">
          <el-select
            v-model="table.params.organization_id"
            filterable
            remote
            placeholder="请输入"
            :remote-method="remoteMethod"
            :loading="selLoading"
            clearable
            @change="onSearch">
              <el-option v-for="item in organizationArr" :label="item.name" :value="item.id" :key="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="服务人员:" prop="worker_name" v-if="table.params.status == 3">
          <el-input clearable v-model.trim="table.params.worker_name" placeholder="请输入" @clear="onSearch" @keydown.enter.native="onSearch"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="theneBtn" type="primary" icon="el-icon-search" @click="onSearch">查询 </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="curTableWrap" ref="main">
      <el-table
        :data="table.data"
        ref="tableDom"
        :height="height"
        border
        style="width: 100%"
        v-loading="table.loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0)"
        :header-cell-style="{background:'#f5f5f5'}"
        class="chTable"
      >
        <el-table-column
          label="序号"
          type="index"
          key="0"
          width="60">
        </el-table-column>
        <el-table-column
          label="服务名称"
          prop="item_str"
          min-width="140"
          key="1">
        </el-table-column>
        <el-table-column
          label="服务规格"
          min-width="140"
          prop="spec_str"
          key="2">
        </el-table-column>
        <el-table-column
          label="需求类别"
          min-width="140"
          prop="parent_kinds_str"
          :show-overflow-tooltip="true"
          key="3">
          <template slot-scope="{row}">
            <span>{{row.parent_kinds_str}}/{{row.kinds_str}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="订单金额"
          prop="amount"
          key="4">
          <template slot-scope="{row}">
           <span>￥{{row.amount}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="下单地区"
          prop="province_str"
          :show-overflow-tooltip="true"
          min-width="120px"
          key="5">
          <template slot-scope="{row}">
            <span>{{row.province_str}}-{{row.city_str}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="下单人"
          prop="name"
          width='180'
          key="6">
          <template slot-scope="{row}">
            <span>{{row.name}}</span>
            <span style="margin-left:10px;">{{row.account}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="服务时间"
          prop="show_service_time"
          width="160"
          key="9">
        </el-table-column>
        <el-table-column
          v-if="table.params.status > 2 && table.params.status < 5"
          label="服务商"
          prop="organization_str"
          width="160"
          key="7">
          <template slot-scope="{row}">
            <el-link type="primary" v-if="!!row.organization_str && !row.worker_name" @click="showAssign(row, 1)">
              {{row.organization_str}}
              <i class="el-icon-edit"></i>
            </el-link>
            <span v-else>{{row.organization_str}}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="table.params.status > 2 && table.params.status < 5"
          label="指派人员"
          prop="worker_name"
          width="180"
          key="8">
          <template slot-scope="{row}">
            <template v-if="table.params.status != 3">
              <span>{{row.worker_name}}</span>
              <span style="margin-left:10px;">{{row.worker_account}}</span>
            </template>
            <template v-else>
              <el-link type="primary" v-if="!!row.worker_name" @click="showAssign(row, 2)">
                <span>{{row.worker_name}}</span>
                <span style="margin-left:10px;">{{row.worker_account}}</span>
                <i class="el-icon-edit"></i>
              </el-link>
              <el-link type="primary" v-else  @click="showAssign(row, 2)">未指派<i class="el-icon-edit"></i></el-link>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          v-if="table.params.status == 1"
          label="下单时间"
          prop="create_time"
          width="150"
          key="10">
        </el-table-column>
        <el-table-column
          v-if="table.params.status > 1 && table.params.status < 4"
          label="支付时间"
          prop="create_time"
          width="160"
          key="11">
        </el-table-column>
        <el-table-column
          v-if="table.params.status == 4"
          label="完成时间"
          prop="complete_time"
          width="160"
          key="12">
        </el-table-column>
        <el-table-column
          v-if="table.params.status == 5"
          label="取消原因"
          prop="cancel_reason"
          min-width="120"
          :show-overflow-tooltip="true"
          key="13">
        </el-table-column>
        <el-table-column
          v-if="table.params.status == 5"
          label="取消时间"
          prop="cancel_time"
          width="160"
          key="14">
        </el-table-column>
        <el-table-column
          label="操作"
          prop="action"
          key="20"
          fixed="right"
          :width="(table.params.status > 1 && table.params.status < 4 ) ? '160' : '100'">
          <template slot-scope="{row}">
            <el-button type="text" icon="el-icon-view" @click="showDetail(row)">查看</el-button>
            <el-button type="text" icon="el-icon-finished" v-if="table.params.status == 3" @click="handleRefund(row)">退款</el-button>
            <el-popover
              v-if="table.params.status == 2"
              placement="top"
              width="260">
              <div style="text-align: center; margin: 0">
                <el-radio-group v-model="selForm.assign_type" size="small" @change="showAssign(row)">
                  <el-radio label="1" border>指派给机构</el-radio>
                  <el-radio label="2" border>指派给师傅</el-radio>
                </el-radio-group>
              </div>
              <el-button slot="reference" type="text" style="margin-left:10px;">指派服务方</el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <div class='pagination__wrap'>
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :current-page="table.params.page"
          :page-size="table.params.count"
          :total="table.total"
          :page-sizes="[10, 20, 50, 100, 150]"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 退款 -->
    <el-dialog
      title="退款"
      :visible.sync="dialogVisibleForm"
      width="500px"
      center>
      <el-form
        v-loading="form.data.loading"
        element-loading-text="加载中…"
        ref="elFormDom"
        label-width="130px"
        label-position="right"
        :model="form.data"
        :rules="form.rules"
      >
        <el-form-item prop="amount" label="订单金额：">
          ￥<span>{{form.data.amount}}</span>元
        </el-form-item>
        <el-form-item prop="cancel_amount" label="退款金额">
          <el-input v-model="form.data.cancel_amount" placeholder="请输入" @input="form.data.cancel_amount=form.data.cancel_amount.match(/\d+(\.\d{0,2})?/) ? form.data.cancel_amount.match(/\d+(\.\d{0,2})?/)[0] : ''"><template slot="suffix">元</template>
          </el-input>
        </el-form-item>
        <el-form-item prop="cancel_reason" label="退款原因">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="请输入100字内"
            v-model="form.data.cancel_reason"
            maxlength="100">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleForm = false">取 消</el-button>
        <el-button type="primary" :loading="form.loading" @click="refundConfirm">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 指派给机构，指派给师傅 -->
    <el-dialog
      title=""
      :visible.sync="dialogVisibleTable"
      :before-close="handleClose"
      width="800px"
      center>
      <el-form
        :model="selForm"
        @submit.native.prevent
        inline
        size="mini"
      >
        <!-- 指派给机构 -->
        <template v-if="selForm.assign_type == 1">
          <el-form-item prop="name" label="">
            <el-input v-model.trim="selForm.name" placeholder="请输入企业名称" @keydown.enter.native="getOrganList" >
            </el-input>
          </el-form-item>
          <el-form-item prop="contact" label="">
            <el-input v-model.trim="selForm.contact" placeholder="请输入联系人" @keydown.enter.native="getOrganList" >
            </el-input>
          </el-form-item>
          <el-form-item prop="phone" label="">
            <el-input v-model.trim="selForm.phone" placeholder="请输入电话" maxlength="11" @keydown.enter.native="getOrganList" >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="theneBtn" type="primary" icon="el-icon-search" @click="getOrganList">查询 </el-button>
            <el-button @click="resetOrgan">重置</el-button>
          </el-form-item>
        </template>
        <!-- 指派给师傅： -->
        <template v-if="selForm.assign_type == 2">
          <el-form-item prop="name" label="">
            <el-input v-model.trim="selForm.name" placeholder="请输入姓名" @keydown.enter.native="getUserList">
            </el-input>
          </el-form-item>
          <el-form-item prop="account" label="">
            <el-input v-model.trim="selForm.account" placeholder="请输入电话" maxlength="11" @keydown.enter.native="getUserList">
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="theneBtn" type="primary" icon="el-icon-search" @click="getUserList">查询 </el-button>
            <el-button @click="resetOrgan">重置</el-button>
          </el-form-item>
        </template>
      </el-form>
      <div>
        <!-- 指派给机构 -->
        <el-table
          v-if="selForm.assign_type == 1"
          :data="tableData"
          border
          style="width: 100%"
          highlight-current-row
          @row-click="singleElection"
        >
          <el-table-column align="center" label="选择" width="80">
            <template slot-scope="{row}">
              <el-radio v-model="selForm.organization_id" :label="row.id">&nbsp;</el-radio>
            </template>
          </el-table-column>
          <el-table-column
            prop="simple_name"
            label="企业简称">
          </el-table-column>
          <el-table-column
            prop="contact"
            label="联系人">
          </el-table-column>
          <el-table-column
            prop="phone"
            label="联系电话"
            width="120">
          </el-table-column>
          <el-table-column
            prop="kind_str"
            label="业务范围"
            :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
            prop="count"
            label="派单数量">
          </el-table-column>
          <el-table-column
            prop="score"
            label="综合评分">
            <template slot-scope="{row}">
              <span>{{row.score}}分</span>
            </template>
          </el-table-column>
        </el-table>

        <!-- 指派给师傅 -->
        <el-table
          v-if="selForm.assign_type == 2"
          :data="userList"
          style="width: 100%"
          :show-header="false"
          highlight-current-row
          @row-click="singleElection"
        >
          <el-table-column align="center" label="选择" width="80">
            <template slot-scope="{row}">
              <el-radio v-model="selForm.organization_id" :label="row.id">&nbsp;</el-radio>
            </template>
          </el-table-column>
          <el-table-column
            prop="image"
            label="头像"
            width="100">
            <template slot-scope="{row}">
              <el-image v-if="!!row.avatar" :src="row.avatar"  style="width:60px;height:60px;border-radius: 50%;"> </el-image>
              <el-avatar v-else size="large" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
            </template>
          </el-table-column>
          <el-table-column prop="sex" label="性别" width="60">
            <template slot-scope="{row}">
              <span>{{row.sex === 0 ? '未填' : (row.sex === 1 ? '男' : '女')}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="nickname"
            label="联系人">
            <template slot-scope="{row}">
              <div>
                <span style="margin:0 10px;">{{row.nickname}}</span>
                <span>{{row.account}}</span>
              </div>
              <div>
                <span v-if="row.kinds.length > 0" >{{row.kinds.join(",")}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="score"
            label="综合评分"
            width="100">
            <template slot-scope="{row}">
              <span>{{row.score}}分</span>
            </template>
          </el-table-column>
        </el-table>

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="selForm.loading" @click="assignConfirm">确 定</el-button>
      </span>
    </el-dialog>

    <detail ref="detail"></detail>
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Detail from './components/Detail.vue';
import { mixinTable } from '@/mixins/table.js'
import { mapGetters } from 'vuex'
export default {
  name: 'OrderList',
  components: {
    VTable,
    Detail,
  },
  mixins: [mixinTable],
  computed: {
    ...mapGetters(['setKinds'])
  },
  data() {
    return {
      height: 0,
      table: {
        loading: false,
        params: {
          status: 1, //0-全部 1-待支付 2-待派单 3-待服务 4-已完成 5-已取消
          item_str: '', //服务名称
          kinds_arr: [],
          parent_kinds:'', // 一级需求类别
          kinds: '', // 二级需求类别
          city: '',
          account: '', //下单人
          organization_id: '',// 服务商ID
          worker_name: '', //服务人员
          order_no: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
        paying_total: 0, //待支付
        sending_total: 0, //待派送
        servicing_total: 1, //待服务
        finish_total: 0, //已完成总数
        cancel_total: 0, //已取消总数
      },
      dialogVisibleForm: false,
      dialogVisibleTable: false,
      form: {
        loading: false,
        data: {
          id: '',
          amount: 0,
          cancel_amount: '', // 退款金额
          cancel_reason: '', // 原因
        },
        rules: {
          cancel_amount: [{ required: true, message:'请输入退款金额', trigger: 'change' }],
          cancel_reason: [{ required: true, message:'请输入原因', trigger: 'change' }],
        }
      },
      areaArr: [],
      selLoading: false,
      organizationArr: [], // 服务商
      kindListOpt: [], //类别
      selForm: {
        loading: false,
        assign_type: '',
        id: '',
        organization_id: '',
        worker_id: '',

        name: '',
        contact: '',
        phone: '',
        account: '',
      },
      
      tableData: [], // 机构列表
      userList: [], // 师傅列表
      currRow: {},
    }
  },
  created() {
    this.getTable();
    this.getArea();
  },
  mounted() {
    this.height = this.$refs.main.offsetHeight || 500
  },
  methods: {
    getTable() {
      let _params = { ...this.table.params }
      if(this.table.params.kinds_arr.length == 1) {
        _params.parent_kinds = this.table.params.kinds_arr[0];
      } else if(this.table.params.kinds_arr.length > 1) {
        _params.parent_kinds = this.table.params.kinds_arr[0];
        _params.kinds = this.table.params.kinds_arr[1];
      } else {
        _params.parent_kinds = [];
        _params.kinds = [];
      }
      delete _params.kinds_arr
      _params.organization_id = this.table.params.status > 3 ? '' : this.table.params.organization_id;
      _params.worker_name = this.table.params.status == 3 ? this.table.params.worker_name : '';
      this.table.loading = true;
      this.$http.get('/admin/flex_order/serviceList', { params:_params }).then(res => {
        if(res.code === 1) {
          this.table.loading = false
          this.table.data = res.data.list;
          this.table.total = res.data.total;
          this.table.paying_total = res.data.paying_total; // 待支付总数
          this.table.sending_total = res.data.sending_total; // 待派送总数
          this.table.servicing_total = res.data.servicing_total; // 待服务总数
          this.table.finish_total = res.data.finish_total; // 已完成总数
          this.table.cancel_total = res.data.cancel_total; // 已取消总数
          this.$nextTick(() => {
            // 	对 Table 进行重新布局。当 Table 或其祖先元素由隐藏切换为显示时，可能需要调用此方法
            if(this.$refs.tableDom && this.$refs.tableDom.doLayout) {
              this.$refs.tableDom.doLayout()
            }
          })
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 查询机构
    remoteMethod(query) {
      if(query !== '') {
        this.selLoading = true;
        this.$http.get("/admin/flex_organization/list", {params:{page:1,count:1000,check_status:2,name: query}}).then((res) => {
          if(res.code == 1) {
            this.organizationArr = res.data.list;
          }
        }).finally(() => {
          this.selLoading = false;
        })
      } else {
        this.organizationArr = [];
      }
    },
    // 机构列表
    getOrganList() {
      let _params = {
        page:1,
        count:1000,
        city: this.currRow.city,
        kinds: this.currRow.parent_kinds,
        check_status: 2, // 2:审核成功
        status: 1, // 1:未冻结的
        name: this.selForm.name,
        contact: this.selForm.contact,
        phone: this.selForm.phone,
      }
      this.$http.get('/admin/flex_organization/list',{params:_params}).then(res => {
        if(res.code == 1) {
          this.tableData = res.data.list;
        }
      })
    },
    // 获取用户列表
    getUserList() {
      let _params = {
        page:1,
        count:1000,
        city: this.currRow.city,
        kinds: this.currRow.parent_kinds,
        type: 2, // 师傅列表
        is_perfect: 1, // 完善名片
        show_worker: 1, //是否展示工作信息（头像及评分）：1-是
        name: this.selForm.name,
        account: this.selForm.account,
      }
      this.$http.get('/admin/flex_user/list',{params:_params}).then(res => {
        if(res.code == 1) {
          this.userList = res.data.list;
        }
      })
    },
    // 指派
    showAssign(row, type) {
      this.dialogVisibleTable = true;
      this.selForm.id = row.id;
      this.currRow = row;
      
      this.selForm.assign_type = !!type ? type : this.selForm.assign_type;
      if(this.selForm.assign_type == 1) {
        this.getOrganList()
        this.selForm.organization_id = row.organization_id; // 机构id
      } else {
        this.getUserList()
        this.selForm.organization_id = row.user_id; //人员id
      }
    },
    resetOrgan() {
      this.selForm.name = '';
      this.selForm.contact = '';
      this.selForm.phone = '';
      this.selForm.account = '';
      if(this.selForm.assign_type == 1) {
        this.getOrganList();
      } else {
        this.getUserList();
      }
    },
    singleElection(row) {
      if(this.selForm.assign_type == 1) {
        this.selForm.organization_id = row.id; // 机构id
      } else {
        this.selForm.worker_id = row.id; // 师傅id
      }
    },
    // 需求类别
    getKindList() {
      this.$http.get('/admin/flex_kind/all',{params:{}}).then(res => {
        if(res.code == 1) {
          this.kindListOpt = res.data;
        }
      })
    },
    
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    // 查看
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      let obj = {
        ...row,
        selStatus: this.table.params.status, // 查询条件状态
      }
      dom.getDetail(obj)
      dom = null
    },
    // 退款
    handleRefund(row) {
      this.dialogVisibleForm = true;
      this.form.data.cancel_amount = '';
      this.form.data.cancel_reason = '';
      this.$refs.elFormDom.resetFields()
      this.form.data.id = row.id;
      this.form.data.amount = row.amount; // 退款金额
    },
    // 退款方法
    refundConfirm() {
      this.$refs.elFormDom.validate(valid => {
        if(valid) {
          let _params = {
            id: this.form.data.id,
            cancel_amount: this.form.data.cancel_amount,
            cancel_reason: this.form.data.cancel_reason,
          }
          this.form.loading = true;
          this.$http.post('/admin/flex_order/refundService', _params).then(res => {
            if(res.code == 1) {
              this.dialogVisibleForm = false;
              this.$message.success('操作成功！')
              this.getTable();
            }
          }).finally(() => {
            this.form.loading = false;
          })
        }
      })
    },

    // 指派方法
    assignConfirm() {
      if(this.selForm.assign_type == 1 && !this.selForm.organization_id) {
        this.$message.warning("请选择机构");
        return
      } else if(this.selForm.assign_type == 2 && !this.selForm.worker_id) {
        this.$message.warning("请选择师傅");
        return
      }
      let _params = {
        id: this.selForm.id,
        organization_id: this.selForm.organization_id,
        worker_id: this.selForm.worker_id,
      }
      let apiurl = this.selForm.assign_type == 1 ? '/admin/flex_order/setOrganization' : '/admin/flex_order/setWorker'
      this.selForm.loading = true;
      this.$http.post(apiurl, _params).then(res => {
        if(res.code == 1) {
          this.dialogVisibleTable = false;
          Object.keys(this.selForm).forEach(item => {
            this.selForm[item] = ''
          })
          this.getTable();
        }
      }).finally(() => {
        this.selForm.loading = false;
      })
    },
    handleClose() {
      this.dialogVisibleTable = false;
      this.selForm.assign_type = '';
      this.selForm.organization_id = ''
    }

  }
}
</script>

<style scoped>
.curTableWrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 24px;
  background: #fff;
  flex: 1;
  overflow-y: scroll;
}
.pagination__wrap {
  margin: 10px 0;
  text-align: right;
  /* padding-right: 40px; */
}
</style>